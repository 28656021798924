<!--
 * @FilePath: \zsw_new_edition\src\components\SuList.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-08 14:55:08
-->
<!--模型列表组件-->
<template>
	<div ref="box">
		<el-skeleton
				style="width: 100%"
				:loading="loading && !list.length"
				animated
				:count="5"
		>
			<div slot="template">
				<el-skeleton-item
						variant="image"
						style="width: 3.2rem; height: 2.7rem; margin-bottom: 0.4rem"
				/>
			</div>
			<div v-if="list.length > 0 || loading">
				<div class="suList">
					<router-link
							:to="{ path: '/schemeText/detail', query: { detailId: item.id } }"
							target="_blank"
							class="suItem"
							v-for="(item, index) in list"
							:key="index"
					>
						<div class="suItemImg">
							<img :src="item.img" alt="" />
						</div>
						<div class="suItemTitle flex-sb">
							<div class="text">{{ item.title }}</div>
							<div v-if="item.total_page" class="count">{{ item.total_page }}页</div>
						</div>
					</router-link>
					<div style="height: 0; width: 3.2rem"></div>
					<div style="height: 0; width: 3.2rem"></div>
					<div style="height: 0; width: 3.2rem"></div>
				</div>
				<div style="height: 40px;">
					<img class="loadImg" :class="{ loadImgs: !loading }" src="@/assets/image/loading.gif" alt="">
					<p class="loadText" :class="{ loadTexts: !loading && !listStatus.isData }">
						没有更多了...
					</p>
				</div>
			</div>
			<div v-else>
				<el-empty description="没有内容哦"></el-empty>
			</div>
		</el-skeleton>
	</div>
</template>

<script>
export default {
  name: "SchemeTextList",
  props: {
    schemeTextList: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
		infinite: {
			type: Boolean,
			default: false, // 判断是否需要无限滚动
		},
		listStatus: {
			type: Object,
			default: () => {
				return {
					isData: true,	// 判断是否有下一页
					isNull: false	// 判断是否为空
				}
			}
		}
	},
	data() {
		return {
			list: [],
			isLoading: false,
		};
	},
	methods: {
		// 清除数据
		clear() {
			this.list = []
		},
		// 翻页
		loadmore() {
			if (this.listStatus.isNull || !this.listStatus.isData) return;
			this.$emit("nextPage");
		},
		// 添加数据
    pushData() {
			if (this.schemeTextList && this.schemeTextList.data && this.schemeTextList.current_page > 1) {
				this.list = this.list.concat(this.schemeTextList.data);
			} else if (this.schemeTextList && this.schemeTextList.data && this.schemeTextList.data.length){
				this.list = this.schemeTextList.data
				this.$waterfall.forceUpdate() 
			}
    },
		fnThrottle (method, delay, duration) {
			var that = this;
			var timer = this.timer;
			var begin = new Date().getTime();
			return function(){
				var context = that;
				var args = arguments;
				var current = new Date().getTime();
				clearTimeout(timer);
				if(current-begin>=duration){
					method.apply(context,args);
					begin=current;
				}else{
					that.timer=setTimeout(function(){
						method.apply(context,args);
					}, delay);
				}
			}
		},
		// 判断滚动位置
		handleScroll() {
			const scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			const windowHeight =
				document.documentElement.clientHeight || document.body.clientHeight;
			const offsetTop = this.$refs.box.offsetHeight;
			if (scrollTop + windowHeight >= offsetTop && !this.loading) {
				// console.log(this.listStatus.isNull, !this.listStatus.isData,);
				//到了这个就可以进行业务逻辑加载后台数据了
				if (this.listStatus.isNull || !this.listStatus.isData) return;
				this.fnThrottle(this.loadmore, 200, 150)()
			}
		},
	},
	mounted() {
		this.pushData();
		window.addEventListener("scroll", this.handleScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	watch: {
		schemeTextList() {
			this.pushData();
		}
	}
};
</script>

<style scoped>
.el-empty{
	width:100%
}
.loadImgs {
	height: 0;
}
.loadText {
	height: 0;
	line-height: 40px;
	overflow: hidden;
	text-align: center;
	transition: 0.5s;
	color: black;
}
.loadTexts.loadText {
	height: auto;
	display: block;
}
.loadImg {
	display: block;
	margin: 0 auto;
	color: black;
  transition: 0.5s;
	text-align: center;
}
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.suItemTitle {
  width: 100%;
  color: #22242E;
  font-size: 0.14rem;
  margin-top: 0.08rem;
  font-weight: 600;
}
.text {
	flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}
.count {
	font-size: 0.14rem;
	font-weight: 400;
	color: rgba(166, 166, 166, 1);
}

.suItem {
  /* width: 3.2rem; */
	width: 20%;
	padding: 0 0.15rem;
  margin-bottom: 0.3rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.suItem:hover {
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
}
/* .suItem:nth-child(5n) {
	padding-right: 0;
}
.suItem:nth-child(5n+1) {
	margin-left: 0;
} */

.suItemImg {
  /* width: 3.2rem; */
	width: 100%;
  /* height: 2.4rem; */
  border-radius: 0.08rem;
  overflow: hidden;
}
.suItemImg img {
	width: 100%;
  border-radius: 0.08rem;
  /* height: 2.4rem; */
}

::v-deep .suList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>