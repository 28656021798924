<!--分类筛选组件-->
<template>
  <div>
    <div class="classifyCont">
      <div class="classifyBox">
        <div class="classifyItem mt25">
          <div class="classifyItemName mr40">一级分类</div>
          <div class="classifyChild">
            <div
              class="classifyChildItem"
              :class="{ active: activeIndex == -1 }"
              @click="all(1), initData()"
            >
              全部
            </div>
            <div
              class="classifyChildItem"
              :class="{ active: activeIndex == item.parameter }"
              v-for="(item, index) in menuList"
              :key="index"
              @click="getChildMenu(item.parameter)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="classifyItem mt10" v-show="menuChildList.length > 0">
          <div class="classifyItemName mr40 mt20">二级分类</div>
          <div class="classifyChild">
            <div
              class="classifyChildItem mt20"
              :class="{ active: activeChildIndex == -1 }"
              @click="all(2), initData(true)"
            >
              全部
            </div>
            <div
              class="classifyChildItem mt20"
              :class="{ active: activeChildIndex == item.parameter }"
              v-for="(item, index) in menuChildList"
              :key="index"
              @click="selectMenu(item.parameter, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ContBox">
			<div style="height: 0.36rem;"></div>
      <div class="content" :class="{ isFixe: !tagBarFixed }">
        <div class="dfc fl mr60">
          <div class="df_Yc tagName">
            <img class="tagIcon" src="@/assets/image/icon/px-icon.png" alt="" />
            <div class="tagTile">排序</div>
          </div>
          <div class="df_Yc">
            <div
              class="tag"
              v-for="(item, index) in pxOption"
              :key="index"
              :class="{ active: px == item.value }"
              @click="selectPx(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="dfc fl mr60">
          <div class="df_Yc tagName">
            <img
              class="tagIcon"
              src="@/assets/image/icon/screen-icon.png"
              alt=""
            />
            <div class="tagTile">筛选</div>
          </div>
          <div class="df_Yc">
            <div
              class="tag"
              v-for="(item, index) in freeOption"
              :key="index"
              :class="{ active: free == item.value }"
              @click="selectFree(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
				<div class="dfc fl mr60">
					<div class="df_Yc tagName">
						<img
							class="tagIcon"
							src="@/assets/image/icon/classify-icon.png"
							alt=""
						/>
						<div class="tagTile">类型</div>
					</div>
					<div class="df_Yc">
						<div
							class="tag"
							v-for="(item, index) in countryOption"
							:key="index"
							:class="{ active: country == item.value }"
							@click="selectCountry(item.value)"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
<!--        <div class="dfc fl mr60">-->
<!--          <div class="df_Yc tagName">-->
<!--            <img-->
<!--              class="tagIcon"-->
<!--              src="@/assets/image/icon/zan-icon.png"-->
<!--              alt=""-->
<!--            />-->
<!--            <div class="tagTile">推荐</div>-->
<!--          </div>-->
<!--          <div class="df_Yc">-->
<!--            <div-->
<!--              class="tag"-->
<!--              v-for="(item, index) in ReComOption"-->
<!--              :key="index"-->
<!--              :class="{ active: reCom == item.value }"-->
<!--              @click="selectReCom(item.value)"-->
<!--            >-->
<!--              {{ item.label }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="dfc fr">
          <div class="df_Yc tagName" style="margin-right: 0.2rem">
            <div class="tagTile">设计时间</div>
          </div>
          <div class="select">
            <el-date-picker
                @change="selectTime"
                value-format="yyyy"
                v-model="dataTime"
                type="year"
                placeholder="选择年份"
              >
              </el-date-picker>
          </div>
        </div>
      </div>
      <div class="content" :class="{ isFixe1: tagBarFixed }">
        <div class="fixedContent">
          <div class="fl mr40">
            <el-select
              v-model="px"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectPx"
            >
              <el-option
                v-for="item in pxOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df_Yc fl mr40">
            <p class="f14 f0404 mr10">筛选</p>
            <el-select
              v-model="free"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectFree"
            >
              <el-option
                v-for="item in freeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
					<div class="df_Yc fl mr40">
						<p class="f14 f0404 mr10">类型</p>
						<el-select
							v-model="country"
							placeholder="请选择"
							style="width: 1.25rem"
							@change="selectCountry"
						>
							<el-option
								v-for="item in countryOption"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</div>
<!--          <div class="df_Yc fl mr40">-->
<!--            <p class="f14 f0404 mr10">推荐</p>-->
<!--            <el-select-->
<!--              v-model="reCom"-->
<!--              placeholder="请选择"-->
<!--              style="width: 1.25rem"-->
<!--              @change="selectReCom"-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="item in ReComOption"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value"-->
<!--                :disabled="item.disabled"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
          <div class="dfc fl">
            <div class="df_Yc tagName" style="margin-right: 0.2rem">
              <div class="tagTile">时间</div>
            </div>
            <div class="select">
              <el-date-picker
                @change="selectTime"
                value-format="yyyy"
                v-model="dataTime"
                type="year"
                placeholder="选择年份"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="searchF fr">
            <el-input
              placeholder="请输入关键词"
              v-model="keysCont"
              @keyup.enter.native="searchKey"
              clearable
              @clear="searchKey"
            ></el-input>
            <i class="iconfont f20 c_99 c_p" @click="searchKey">&#xe67d;</i>
          </div>
          <div class="df_Yc fr mr40">
            <p class="f14 f0404 mr10">分类</p>
            <el-cascader
              ref="refHandle"
              :props="props"
              :show-all-levels="false"
              v-model="cloudList"
              @change="handleChange"
            ></el-cascader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMenu } from "../Api";
export default {
  name: "SchemeTextClassifyMenu",
  props: ["keys"],
  data() {
    return {
      menuList: [],
      menuChildList: [],
      activeIndex: -1,
      activeChildIndex: -1,
      cloud: null,
      cloudList: [],
      px: 1,
      free: 0,
      reCom: 0,
			country: 0,
      keysCont: "",
      dataTime: null,
      tagBarFixed: false,
      //排序选项
      pxOption: [
        // {
        //   value: 0,
        //   label: "综合",
        // },
        {
          value: 1,
          label: "最新",
        },
        {
          value: 2,
          label: "热门",
        },
      ],
      //  筛选选项
      freeOption: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "免费",
        },
        {
          value: 2,
          label: "付费",
        },
        {
          value: -1,
          label: "VIP",
        },
      ],
			// 类型筛选项
			countryOption: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "国内",
        },
        {
          value: 2,
          label: "国外",
        },
      ],
      //  推荐选项
      ReComOption: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "PPT",
        },
        {
          value: 2,
          label: "PDF",
        }
      ],
      props: {
        checkStrictly: true,
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
      getListParams: {
        id: 1394,
        child: null,
        free: 0,
				country: 0,
        page: 1,
        px: 1,
        reCom: 0,
        videoType: 0,
        key: "",
        dataTime: null,
        comeDownBanner: "",
      },
    };
  },
  created() {
    //获取路由参数
    if (this.$route.query.child1 && this.$route.query.child2) {
      this.activeIndex = this.$route.query.child1;
      this.activeChildIndex = this.$route.query.child2;
      this.cloudList = [
        parseInt(this.$route.query.child1),
        parseInt(this.$route.query.child2),
      ];
      this.getMenu(1394).then((res) => {
          this.menuChildList = [];
          this.menuList = res.data.data;
        });
      this.getListParams.child = this.activeChildIndex;
    } else {
      if (this.$route.query.child1) {
        this.activeIndex = this.$route.query.child1;
        this.cloudList = [parseInt(this.activeIndex)];
        this.getMenu(1394).then((res) => {
          this.menuChildList = [];
          this.menuList = res.data.data;
        });
        this.getListParams.child = this.$route.query.child1;
      } else {
        this.initData(false);
        this.getListParams.child = 1394;
      }
    }
    this.keysCont = this.keys;
    if (this.$route.query.search) {
      this.getListParams.key = this.$route.query.search;
    }
    if (this.$route.query.sort) {
      this.px = parseInt(this.$route.query.sort) || 0;
      this.getListParams.px = this.px;
    }
    if (this.$route.query.free) {
      this.free = parseInt(this.$route.query.free) || 0;
      this.getListParams.free = this.free;
    }
    if (this.$route.query.country) {
      this.country = parseInt(this.$route.query.country) || 0;
      this.getListParams.country = this.country;
    }
    if (this.$route.query.reCom) {
      this.reCom = parseInt(this.$route.query.reCom) || 0;
      this.getListParams.reCom = this.reCom;
    }
    if (this.$route.query.dataTime) {
      this.dataTime = this.$route.query.dataTime;
      this.getListParams.dataTime = this.dataTime;
    }
    this.$emit("changeData", this.getListParams);
  },
  methods: {
    all(type) {
      if (type == 1) {
        this.activeIndex = -1;
        this.activeChildIndex = -1;
        //将参数设置到地址栏
        const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
        // query.child1 = this.activeIndex; //改变参数
        delete query.child1
        delete query.child2
        this.$router.push({ path: this.$route.path, query });
      } else {
        this.activeChildIndex = -1;
        //将参数设置到地址栏
        const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
        // query.child2 = this.activeChildIndex; //改变参数
        delete query.child2
        this.$router.push({ path: this.$route.path, query });
      }
    },
    initData(isChild) {
      if (!isChild) {
        if (this.$refs.refHandle) this.resetSelectMenu();
        this.getMenu(1394).then((res) => {
          this.menuChildList = [];
          this.menuList = res.data.data;
        });
        this.cloud =1394;
      } else {
        if (this.activeIndex != -1) this.getChildMenu(this.activeIndex);
      }
    },
    //获取菜单
    getMenu(id) {
      return new Promise((resolve) => {
        getMenu(id).then((res) => {
          resolve(res);
        });
      });
    },
    //动态加载菜单
    lazyLoad(node, resolve) {
      const { level } = node;
      const id = level == 0 ? 1394 : node.value;
      this.getMenu(id).then((res) => {
        if (level == 1) {
          this.menuChildList = res.data.data;
        }
        const nodes = res.data.data.map((item) => ({
          value: item.parameter,
          label: item.name,
          leaf: node.level >= 1,
        }));
        resolve(nodes);
      });
    },
    // 获取二级菜单
    getChildMenu(id) {
      this.getMenu(id).then((res) => {
        this.resetSelectMenu();
        this.menuChildList = res.data.data;
      });
      this.activeIndex = id;
      this.cloud = id;
      this.cloudList = [parseInt(this.activeIndex)];
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      if (query.child1 != id) {
        query.child1 = id;
        delete query.child2;
      }
      //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //重置菜单选择器
    resetSelectMenu() {
      this.$refs.refHandle.$refs.panel.checkedValue = [];
      this.$refs.refHandle.$refs.panel.activePath = [];
      this.$refs.refHandle.$refs.panel.syncActivePath();
    },
    // 点击二级菜单
    selectMenu(id) {
      this.cloudList = [parseInt(this.activeIndex), parseInt(id)];
      let cs = this.$refs["refHandle"];
      cs.panel.activePath = [];
      cs.panel.loadCount = 0;
      cs.panel.lazyLoad();
      this.activeChildIndex = id;
      this.cloud = id;
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.child2 = id; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    handleChange() {
      if (this.cloudList.length == 0) {
        if (this.$route.query.child1) {
          this.cloudList = [parseInt(this.activeIndex)];
        }
        if (this.$route.query.child1 && this.$route.query.child2) {
          this.cloudList = [
            parseInt(this.activeIndex),
            parseInt(this.activeChildIndex),
          ];
        }
      }
      let id = null;
      if (this.cloudList.length == 1) {
        // 选定一级菜单
        id = this.cloudList[0];
        this.resetSelectMenu();
        this.menuList.filter((obj) => {
          if (obj.parameter === id) {
            this.getMenu(id).then((res) => {
              this.activeChildIndex = -1;
              this.activeIndex = id;
              this.menuChildList = res.data.data;
              //将参数设置到地址栏
              const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
              query.child1 = this.activeIndex; //改变参数
              this.$router.push({ path: this.$route.path, query });
            });
          }
        });
      } else {
        // 选定二级菜单
        id = this.cloudList[1];
        let parentId = this.cloudList[0];
        this.menuList.filter((obj) => {
          if (obj.parameter === parentId) {
            this.activeIndex = parentId;
            const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
            query.child1 = this.activeIndex; //改变参数
            this.$router.push({ path: this.$route.path, query });
          }
        });
        this.menuChildList.filter((obj) => {
          if (obj.parameter === id) {
            this.activeChildIndex = id;
            //将参数设置到地址栏
            const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
            query.child2 = this.activeChildIndex; //改变参数
            this.$router.push({ path: this.$route.path, query });
          }
        });
      }
			this.cloud = id;
      // 监听值发生变化就关闭它
      if (this.$refs.refHandle)
        return (this.$refs.refHandle.dropDownVisible = false);
    },
    //选择排序
    selectPx(sort) {
      this.px = sort;
      this.$emit("selectSort", sort);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.sort = sort; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择筛选
    selectFree(free) {
      this.free = free;
      this.$emit("selectFreeFun", free);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.free = free; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //类型筛选
    selectCountry(country) {
      this.country = country;
      this.$emit("selectCountryFun", country);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.country = country; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择推荐
    selectReCom(reCom) {
      this.reCom = reCom;
      this.$emit("selectReComFun", reCom);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.reCom = reCom; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择日期
    selectTime() {
      this.$emit("selectTimeFun", this.dataTime);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.dataTime = this.dataTime; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //搜索
    searchKey() {
      this.$emit("searchKeyFun", this.keysCont);
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const offsetTop = document.querySelector(".ContBox").offsetTop * 5;
      if (scrollTop > offsetTop) {
        this.tagBarFixed = true;
      } else {
        this.tagBarFixed = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    cloud(val) {
      this.$emit("changeMenu", val);
    },
    keys(val) {
      this.keysCont = val;
    },
  },
};
</script>

<style scoped>
.searchF >>> .el-input__inner {
  border: 0;
}
::v-deep .el-cascader {
  line-height: 0.28rem;
}
.searchF {
  width: 2.2rem;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-right: 0.1rem;
}
.classifyChildItem.active {
  background: #f46600;
  border-radius: 0.2rem;
  color: #ffffff;
}

.classifyChildItem:hover {
  background: #f46600;
  border-radius: 0.2rem;
  color: #ffffff;
  transform: translateY(-2px);
}

.classifyChildItem {
  cursor: pointer;
  margin-right: 0.15rem;
  padding: 0.05rem 0.15rem;
  transition: 0.2s;
}

.classifyChild {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  font-size: 0.14rem;
  color: #333333;
}

.classifyItem {
  display: flex;
}

.classifyItemName {
  color: #040404;
  font-size: 0.14rem;
  font-weight: bold;
  white-space: nowrap;
  padding: 0.05rem 0;
}

.classifyCont {
  width: 100%;
  padding: 0 1.2rem;
}

.classifyBox {
  padding: 0 0.3rem 0.3rem;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 0.08rem;
  box-shadow: 0px 0px 0.2rem rgba(153, 153, 153, 0.15);
}
.fixedContent {
  width: 100%;
  min-width: 1400px;
  padding: 0 1.2rem;
}
.content {
  /* visibility: hidden; */
  opacity: 0;
  transition: opacity 0.5s;
  padding: 0 1.2rem;
}
.content.isFixe {
  visibility: visible;
  opacity: 1;
  /* margin-top: 0.4rem; */
}
.content.isFixe1 {
  /* visibility: visible; */
  opacity: 1;
  padding: 0.2rem 0;
  background: #ffffff;
  position: fixed;
  top: 0.68rem;
  width: 100%;
  z-index: 8;
}
.select {
  width: 1.45rem;
}

::v-deep .el-picker-panel {
  margin: 5px 1.2rem 5px 0 !important;
}

::v-deep .el-input {
  width: 100% !important;
  font-size: 0.14rem !important;
}

::v-deep .el-input__inner {
  height: 0.28rem;
  line-height: 0.28rem;
}

::v-deep .el-input__icon {
  line-height: 0.28rem;
}

.content:after {
  content: "";
  visibility: hidden;
  display: block;
  width: 0;
  height: 0;
  clear: both;
}

.select .el-input__inner.el-input__inner {
  background-color: #f7f6fb;
}

.tagName {
  margin-right: 0.2rem;
  line-height: 0.28rem;
}

.tagIcon {
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.08rem;
}

.tagTile {
  color: #040404;
  font-size: 0.14rem;
}

.ContBox {
  width: 100%;
  min-width: 12rem;
	height: 1rem;
}

.tag {
  padding: 0.04rem 0.1rem;
  background: #fff;
  border-radius: 0.04rem;
  margin-right: 0.15rem;
  font-size: 0.14rem;
  color: #333333;
  cursor: pointer;
  transition: 0.15s;
}

.tag:hover {
  background: #f46600;
  color: #ffffff;
  transform: translateY(-2px);
}

.tag.active {
  background: #f46600;
  color: #ffffff;
}
</style>